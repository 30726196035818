<template>
  <div
    :class="[
      organism.containerWidth ? 'container-' + organism.containerWidth : '',
      'px-4 organism image xl:px-0',
    ]"
  >
    <div v-if="organism.link1">
      <NuxtLink :href="organism.link1" class="hover:opacity-80">
        <img class="mx-auto" :src="getImageSrc(organism.image)" alt="" loading="lazy"/>
      </NuxtLink>
    </div>
    <div v-else>
      <img :src="getImageSrc(organism.image)" alt="" loading="lazy"/>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  organism: Object,
});

onMounted(() => {
  if (props.organism.containerWidth === "full-width") {
    addFullWidthSupport();
  }
});
</script>
